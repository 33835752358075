import React, { useState, useEffect } from "react";

import { Box, Hidden, Typography } from "@mui/material";

export interface ScoreProps {
  evaluation?: string;
  icon?: React.ReactNode;
  text?: string;
}

export const Score = ({ evaluation, icon, text }: ScoreProps) => {
  const [finalWidth, setFinalWidth] = useState(!evaluation ? "93%" : "auto");
  useEffect(() => {
    if (evaluation) {
      setTimeout(() => setFinalWidth("auto"), 1100);
    }
  }, [evaluation]);
  return (
    <>
      <Hidden mdDown>
        <Box
          bgcolor="secondary.main"
          height={33}
          display="flex"
          px={4}
          color="common.white"
          borderRadius={7}
          alignItems="center"
          style={{
            transition: "width 1s ease-in-out",
            width: !evaluation ? "150px" : finalWidth,
          }}
        >
          <Box mx={0.5}>
            <Typography sx={{ textTransform: "uppercase" }} noWrap>
              {`Score ${evaluation ?? "?"}`}
            </Typography>
          </Box>

          <Box mx={0.5}>{icon}</Box>
          <Box
            mx={0.5}
            style={{
              transition: "width 1s ease-in-out",
              width: !evaluation ? "0px" : "100%",
            }}
          >
            <Typography variant="body1" noWrap>
              {text}
            </Typography>
          </Box>
        </Box>
      </Hidden>
      {evaluation && (
        <Hidden mdUp>
          <Box
            bgcolor="secondary.main"
            height="auto"
            px={4}
            py={2}
            color="common.white"
            borderRadius={7}
            alignItems="center"
            style={{
              transition: "height 1s ease-in-out",
              height: !evaluation ? "50px" : "auto",
            }}
          >
            <Box display="flex" justifyContent="center">
              <Box mx={0.5}>
                <Typography sx={{ textTransform: "uppercase" }} noWrap>
                  {`Score ${evaluation ?? "?"}`}
                </Typography>
              </Box>
              <Box mx={0.5}>{icon}</Box>
            </Box>

            <Box my={0.5} textAlign="center">
              <Typography variant="body1">{text}</Typography>
            </Box>
          </Box>
        </Hidden>
      )}
    </>
  );
};
