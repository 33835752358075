import React from "react";

import { Box } from "@mui/material";

export interface BubbleProps {
  children: React.ReactNode;
}

export const Bubble = ({ children }: BubbleProps) => {
  return (
    <Box
      bgcolor="#F7F8FB"
      borderRadius={16}
      p={{ xs: 2, md: 4 }}
      position="relative"
    >
      {children}
    </Box>
  );
};
