import { version } from "../package.json";
import { Box, Container, Grid, ThemeProvider, Typography } from "@mui/material";
import { Header } from "./components/Header/Header";
import { Calculator } from "./containers/Calculator/Calculator";
import { Faq } from "./containers/Faq/Faq";
import theme from "./theme";
import { Provider } from "react-redux";
import { persistor, store } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { IntercomProvider } from "react-use-intercom";
import { isIntercomEnabled } from "./lib/env";

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <IntercomProvider
          appId={process.env.REACT_APP_INTERCOM_APP_ID!}
          autoBoot={isIntercomEnabled}
        >
          <ThemeProvider {...{ theme }}>
            <Header />
            <Box position="relative" zIndex={1}>
              <Container>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <Box my={4} mx={{ sm: 2, md: 4 }} textAlign={"center"}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography variant="h1" component="h1">
                            Calculez la valeur de votre contenu
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="h2" component="h2">
                            Ajoutez vos réseaux et remplissez le tableau avec la
                            moyenne de vos scores.
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                    <Calculator />
                  </Grid>
                  <Grid item xs={12}>
                    <Faq />
                  </Grid>
                </Grid>
              </Container>
            </Box>
            <Box
              position="fixed"
              left={0}
              bottom={0}
              width="100%"
              bgcolor="#494F96"
              height={{ xs: "47vh", sm: "37vh", md: "27vh", lg: "17vh" }}
            >
              <Box
                position={"absolute"}
                bottom={0}
                left={0}
                width={"100%"}
                textAlign={"center"}
              >
                <Typography variant="caption" style={{ color: "white" }}>
                  Reach Calculator v{version} | © Reachmaker{" "}
                  {new Date().getFullYear()}
                </Typography>
              </Box>
            </Box>
          </ThemeProvider>
        </IntercomProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
