import React from 'react';

export interface BrandySVGProps {}

export const BrandySVG = () => {
  return (
    <svg
      width='126'
      height='116'
      viewBox='0 0 126 116'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g filter='url(#filter0_d)'>
        <path
          d='M88.2329 39.0033C90.3608 37.6514 92.437 35.5984 91.0594 33.3938C88.5951 29.4584 78.0655 32.5726 78.0655 32.5726C80.4715 26.8116 79.359 22.655 76.6167 21.5243C74.3335 20.5831 69.6378 21.2906 65.4273 27.279C62.8789 30.9113 62.271 33.6276 62.1933 35.2131C50.9457 38.1821 42.7898 46.7668 42.7898 56.9055C42.7898 69.4952 55.3503 79.7097 70.8473 79.7097C86.3443 79.7097 98.8984 69.4952 98.8984 56.9055C98.9113 49.641 94.7331 43.1788 88.2329 39.0033Z'
          fill='#6C5BED'
        />
      </g>
      <g filter='url(#filter1_d)'>
        <path
          d='M39.9762 65.0208C40.8874 61.9645 38.419 58.5491 34.4629 57.3923C30.5067 56.2355 26.561 57.7754 25.6498 60.8317C24.7387 63.8881 27.2071 67.3035 31.1632 68.4603C35.1193 69.617 39.065 68.0772 39.9762 65.0208Z'
          fill='#6C5BED'
        />
      </g>
      <g filter='url(#filter2_d)'>
        <path
          d='M90.5099 85.802C93.1654 87.5683 97.1539 86.2082 99.4185 82.7642C101.683 79.3203 101.366 75.0966 98.7106 73.3304C96.0551 71.5641 92.0666 72.9242 89.802 76.3681C87.5374 79.8121 87.8544 84.0358 90.5099 85.802Z'
          fill='#6C5BED'
        />
      </g>
      <g filter='url(#filter3_d)'>
        <path
          d='M70.8278 49.5953C71.739 46.539 69.2705 43.1236 65.3144 41.9668C61.3583 40.81 57.4126 42.3499 56.5014 45.4062C55.5902 48.4625 58.0586 51.8779 62.0147 53.0347C65.9708 54.1915 69.9166 52.6516 70.8278 49.5953Z'
          fill='#6C5BED'
        />
      </g>
      <path
        d='M72.7352 57.2287C73.2158 57.3145 73.704 57.3485 74.1898 57.3301C75.4637 57.2743 76.6761 56.8517 77.6596 56.1206C78.6432 55.3895 79.3496 54.3859 79.6813 53.2483C80.0129 52.1108 79.9536 50.8952 79.5114 49.7695C79.0692 48.6437 78.2659 47.6631 77.2124 46.963C78.1825 47.1333 79.1016 47.5154 79.8932 48.0774C80.6848 48.6395 81.3261 49.3652 81.7638 50.1944C82.2014 51.0235 82.4228 51.9322 82.4095 52.8447C82.3962 53.7572 82.1486 54.6472 81.6873 55.4408C81.226 56.2344 80.5643 56.9086 79.7573 57.4074C78.9502 57.9061 78.0211 58.215 77.0472 58.3084C76.0733 58.4018 75.0826 58.277 74.1577 57.9443C73.2328 57.6117 72.4002 57.0807 71.7293 56.3957C72.0312 56.7065 72.3686 56.9859 72.7352 57.2287Z'
        fill='white'
      />
      <path
        d='M75.7889 46.8709C76.275 46.8516 76.7636 46.8844 77.2451 46.9687C78.2952 47.6616 79.0967 48.6309 79.5388 49.7431C79.981 50.8552 80.0422 52.0552 79.7139 53.1777C79.3855 54.3001 78.684 55.2896 77.7059 56.0097C76.7278 56.7298 75.5214 57.145 74.2531 57.1979C73.7694 57.2153 73.2834 57.1809 72.8046 57.0954C71.757 56.4024 70.9576 55.4339 70.5165 54.3233C70.0754 53.2126 70.0142 52.0144 70.3414 50.8934C70.6686 49.7724 71.3681 48.7838 72.3436 48.0634C73.3191 47.3431 74.5227 46.9265 75.7889 46.8709Z'
        fill='#1D0F7D'
      />
      <path
        d='M75.369 63.0038C78.7374 62.8145 81.3577 61.0795 81.2216 59.1286C81.0855 57.1777 78.2446 55.7496 74.8763 55.9388C71.5079 56.1281 68.8877 57.863 69.0237 59.8139C69.1598 61.7649 72.0007 63.193 75.369 63.0038Z'
        fill='#6C5BED'
      />
      <path
        d='M72.998 54.444C73.9528 54.627 74.8659 54.0498 75.0375 53.1546C75.2091 52.2595 74.5742 51.3855 73.6194 51.2025C72.6646 51.0195 71.7515 51.5967 71.5799 52.4918C71.4083 53.3869 72.0432 54.261 72.998 54.444Z'
        fill='white'
      />
      <path
        d='M76.5586 49.3019C76.9763 49.382 77.3706 49.1567 77.4392 48.7986C77.5078 48.4406 77.2248 48.0854 76.8071 48.0053C76.3894 47.9253 75.9951 48.1506 75.9265 48.5086C75.8579 48.8667 76.1409 49.2219 76.5586 49.3019Z'
        fill='white'
      />
      <path
        d='M54.5351 56.1882C55.0069 56.2758 55.4872 56.3071 55.9652 56.2815C57.2111 56.2122 58.3988 55.7714 59.3651 55.0195C60.3314 54.2677 61.0286 53.2418 61.361 52.083C61.6934 50.9241 61.6446 49.6894 61.2212 48.5485C60.7979 47.4076 60.0208 46.4167 58.9969 45.7121C59.9456 45.878 60.8426 46.2598 61.6135 46.8258C62.3844 47.3917 63.0068 48.1254 63.429 48.966C63.8513 49.8065 64.0611 50.7296 64.0411 51.6584C64.0211 52.5872 63.7718 53.495 63.314 54.3062C62.8562 55.1174 62.2031 55.8086 61.4089 56.3224C60.6148 56.8363 59.7026 57.1578 58.7482 57.2603C57.7937 57.3628 56.8246 57.2434 55.9215 56.9119C55.0183 56.5804 54.2071 56.0464 53.5553 55.3544C53.8493 55.6663 54.1779 55.946 54.5351 56.1882Z'
        fill='white'
      />
      <path
        d='M57.568 45.6192C58.0418 45.5922 58.5177 45.6221 58.9851 45.7084C60.0027 46.4161 60.7738 47.4129 61.1925 48.5618C61.6111 49.7106 61.6567 50.9549 61.323 52.1237C60.9892 53.2924 60.2925 54.3281 59.3287 55.0883C58.365 55.8484 57.1814 56.2957 55.941 56.3685C55.465 56.3955 54.9869 56.3652 54.5175 56.2781C53.4964 55.5719 52.7221 54.5748 52.3015 53.4246C51.881 52.2744 51.835 51.0279 52.17 49.8575C52.505 48.6871 53.2043 47.6506 54.1713 46.8913C55.1384 46.1321 56.3253 45.6875 57.568 45.6192Z'
        fill='#1D0F7D'
      />
      <path
        d='M57.1112 62.015C60.4796 61.8257 63.0999 60.0908 62.9638 58.1399C62.8277 56.1889 59.9868 54.7608 56.6185 54.95C53.2501 55.1393 50.6298 56.8743 50.7659 58.8252C50.902 60.7761 53.7429 62.2042 57.1112 62.015Z'
        fill='#6C5BED'
      />
      <path
        d='M54.8195 53.4243C55.6549 53.5844 56.462 53.037 56.6221 52.2015C56.7823 51.3661 56.2348 50.559 55.3994 50.3989C54.564 50.2387 53.7569 50.7861 53.5967 51.6216C53.4366 52.457 53.984 53.2641 54.8195 53.4243Z'
        fill='white'
      />
      <path
        d='M58.3426 48.4982C58.8797 48.6011 59.38 48.346 59.4601 47.9283C59.5401 47.5105 59.1697 47.0884 58.6326 46.9855C58.0955 46.8825 57.5952 47.1377 57.5152 47.5554C57.4351 47.9731 57.8055 48.3952 58.3426 48.4982Z'
        fill='white'
      />
      <path
        d='M79.2655 45.1166C79.9962 45.2443 80.6906 45.5306 81.3037 45.9567C81.9167 46.3829 82.4347 46.9395 82.824 47.5904'
        stroke='#1D0F7D'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M54.9643 44.4903C54.2857 44.6647 53.6522 44.9802 53.1053 45.4159C52.5584 45.8517 52.1105 46.3978 51.7911 47.0186'
        stroke='#1D0F7D'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M68.071 63.0392C69.1934 63.0942 70.2626 63.532 71.1037 64.281C72.4426 62.6886 72.6336 60.5658 71.8821 59.5038C70.5471 57.6299 68.429 59.5682 66.2951 59.1658C64.5536 58.8319 64.0747 57.6109 62.3031 57.5024C60.5315 57.3939 59.8691 59.3141 59.7655 60.5027C59.6991 61.7066 60.0442 62.8955 60.743 63.8708C61.4419 64.8461 62.4522 65.5488 63.6051 65.8614C63.8894 64.1395 65.8203 62.905 68.071 63.0392Z'
        fill='#1D0F7D'
      />
      <path
        d='M63.6539 65.7993C64.3924 66.0365 65.1571 66.1831 65.9309 66.2358C68.5102 66.3969 70.2826 65.4898 71.3425 64.2528C70.473 63.4927 69.3696 63.0461 68.213 62.9861C65.9131 62.8452 63.9286 64.085 63.6539 65.7993Z'
        fill='#FFB3B3'
      />
      <path
        d='M51.8531 62.4887C53.345 62.7746 54.7862 61.7971 55.0722 60.3052C55.3581 58.8134 54.3806 57.3722 52.8887 57.0862C51.3969 56.8002 49.9557 57.7777 49.6697 59.2696C49.3837 60.7615 50.3612 62.2027 51.8531 62.4887Z'
        fill='#7771F4'
      />
      <path
        d='M79.3377 64.6207C80.8892 64.9182 82.3881 63.9015 82.6855 62.35C82.9829 60.7985 81.9663 59.2996 80.4148 59.0022C78.8632 58.7047 77.3644 59.7214 77.0669 61.2729C76.7695 62.8244 77.7862 64.3233 79.3377 64.6207Z'
        fill='#7771F4'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M91.0462 33.4059C92.3304 35.6727 90.2679 37.6127 88.1849 38.9084C94.3743 43.3252 99.0244 49.7414 98.86 57.0667C98.6666 66.3918 91.631 74.3953 81.979 77.793C90.165 74.0835 95.7702 66.8262 95.9467 58.3129C96.1111 50.9877 92.2117 44.3385 86.0223 39.9217C88.1053 38.626 90.1531 36.6215 88.8689 34.3547C86.5716 30.3079 76.3389 33.1145 76.3389 33.1145C78.7827 27.3813 77.7954 23.1544 75.171 21.9272C74.3197 21.5292 73.1095 21.372 71.7015 21.6776C73.7217 20.8392 75.4823 20.984 76.6296 21.5204C79.254 22.7476 80.394 26.7922 77.9502 32.5255C77.9502 32.5255 88.749 29.3591 91.0462 33.4059Z'
        fill='#524E74'
        fillOpacity='0.31'
      />
      <path
        d='M62.0385 35.2627L88.2226 39.1133L88.7375 39.4385C91.1586 40.9676 93.2623 42.949 94.9336 45.2742L82.5017 44.2841L63.3587 42.4138L50.9268 40.7635L51.0839 40.6588C54.4852 38.3913 58.1678 36.5773 62.0385 35.2627Z'
        fill='#202020'
      />
      <rect
        x='73.1016'
        y='39.2041'
        width='3.56561'
        height='0.86731'
        rx='0.433655'
        transform='rotate(165 73.1016 39.2041)'
        fill='white'
      />
      <rect
        x='72.9766'
        y='40.5444'
        width='3.56561'
        height='0.86731'
        rx='0.433655'
        transform='rotate(165 72.9766 40.5444)'
        fill='white'
      />
      <rect
        x='73.2695'
        y='41.7725'
        width='1.92735'
        height='0.86731'
        rx='0.433655'
        transform='rotate(165 73.2695 41.7725)'
        fill='white'
      />
      <defs>
        <filter
          id='filter0_d'
          x='17.7891'
          y='0.180664'
          width='106.109'
          height='108.529'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          />
          <feOffset dy='4' />
          <feGaussianBlur stdDeviation='12.5' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.423529 0 0 0 0 0.356863 0 0 0 0 0.929412 0 0 0 0.4 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow'
            result='shape'
          />
        </filter>
        <filter
          id='filter1_d'
          x='0.460938'
          y='36.0076'
          width='64.7047'
          height='61.8374'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          />
          <feOffset dy='4' />
          <feGaussianBlur stdDeviation='12.5' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.423529 0 0 0 0 0.356863 0 0 0 0 0.929412 0 0 0 0.4 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow'
            result='shape'
          />
        </filter>
        <filter
          id='filter2_d'
          x='63.2891'
          y='51.5566'
          width='62.6386'
          height='64.0191'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          />
          <feOffset dy='4' />
          <feGaussianBlur stdDeviation='12.5' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.423529 0 0 0 0 0.356863 0 0 0 0 0.929412 0 0 0 0.4 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow'
            result='shape'
          />
        </filter>
        <filter
          id='filter3_d'
          x='31.3125'
          y='20.582'
          width='64.7047'
          height='61.8374'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          />
          <feOffset dy='4' />
          <feGaussianBlur stdDeviation='12.5' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.423529 0 0 0 0 0.356863 0 0 0 0 0.929412 0 0 0 0.4 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow'
            result='shape'
          />
        </filter>
      </defs>
    </svg>
  );
};
