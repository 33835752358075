import { useCallback, useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  Button,
  TextField,
  useTheme,
} from "@mui/material";
import { CanalPicker } from "../../components/Canal/CanalPicker";
import { Bubble } from "../Bubble/Bubble";
import { CanalForm } from "../../components/Form/CanalForm";
import { EngagementRate } from "../../components/Result/EngagementRate";
import { useSelector, useDispatch } from "react-redux";
import {
  setCalculatorCanals,
  setCalculatorCanal,
  launchCalculation,
  setCalculatorStatus,
  setCalculatorEmail,
  setCanCalculate,
  isEmail,
} from "../../redux/actions/calculator";
import {
  CalculatorStatus,
  CANALS_ONLY_LIKE,
  CANALS_WITH_ENGAGEMENT,
  CANAL_FIELDS_META,
} from "../../const/calculator.const";
import isEqual from "react-fast-compare";
import { BrandySVG } from "../../components/images/BrandySVG";
import { Score } from "../../components/Score/Score";
import CountUp from "react-countup";
import { useDebounce } from "use-debounce";
import { purple } from "@mui/material/colors";
import { getCanal } from "../../components/Canal/canals";
import React from "react";

export interface DesktopCalculatorProps {
  getScoreData: (score?: number) => any;
}

export const DesktopCalculator = ({ getScoreData }: DesktopCalculatorProps) => {
  const theme = useTheme();
  const calulatorState = useSelector<any, any>(
    (state) => state.calculator,
    isEqual
  );
  const {
    canals,
    status,
    score,
    email: storeEmail,
    canCalculate,
  } = calulatorState;
  const dispatch = useDispatch();
  const [email, setEmail] = useState(storeEmail);
  const [debouncedEmail] = useDebounce(email, 300);
  const [isEmailFocused, setIsEmailFocused] = useState(false);

  useEffect(() => {
    if (!isEmailFocused && storeEmail !== email) {
      setEmail(storeEmail);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeEmail]);

  useEffect(() => {
    dispatch(setCalculatorEmail(debouncedEmail));
    dispatch(setCanCalculate());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedEmail]);

  useEffect(() => {
    if (status === CalculatorStatus.CALCULATING) {
      dispatch(setCalculatorStatus(CalculatorStatus.INITIAL));
    }
    dispatch(setCanCalculate());
  }, [dispatch, status]);

  const emailHelperText = () => {
    if (!email || !isEmail(email))
      return "Saisissez votre adresse email pour lancer le calcul";
    return "";
  };

  const getInitialValues = (name: string) => {
    if (calulatorState[name.toLowerCase()])
      return Object.fromEntries(
        Object.entries(calulatorState[name.toLowerCase()]).map(
          ([key, value]) => {
            const strValue = value as string;
            if (isNaN(+strValue) || +strValue === 0) return [key, ""];
            return [key, value];
          }
        )
      );
    return {};
  };

  const getCanalsWithEngagement = useCallback(() => {
    return canals.filter((item: string) =>
      CANALS_WITH_ENGAGEMENT.map((c: string) => c.toLowerCase()).includes(
        item.toLowerCase()
      )
    );
  }, [canals]);

  const getCanalsWithLikes = useCallback(() => {
    return canals.filter((item: string) =>
      CANALS_ONLY_LIKE.map((c: string) => c.toLowerCase()).includes(
        item.toLowerCase()
      )
    );
  }, [canals]);

  return (
    <Box px={4}>
      <Bubble>
        <Box position="absolute" top={-50} right={0}>
          <BrandySVG />
        </Box>

        <Box my={1} mb={2}>
          <CanalPicker
            selectedCanals={canals}
            onClose={(canals) => {
              dispatch(setCalculatorCanals(canals as any[]));
              dispatch(setCanCalculate());
            }}
          />
        </Box>

        {getCanalsWithEngagement().length > 0 && (
          <Box my={1}>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Card>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={1}></Grid>
                      <Grid item xs={11}>
                        <Grid container spacing={2}>
                          <Grid item xs={3}>
                            <Typography
                              variant="subtitle1"
                              sx={{
                                color: theme.palette.primary.main,
                                textAlign: "center",
                              }}
                              noWrap
                            >
                              Vues
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography
                              variant="subtitle1"
                              sx={{
                                color: theme.palette.primary.main,
                                textAlign: "center",
                              }}
                              noWrap
                            >
                              Likes
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography
                              variant="subtitle1"
                              sx={{
                                color: theme.palette.primary.main,
                                textAlign: "center",
                              }}
                              noWrap
                            >
                              Commentaires
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography
                              variant="subtitle1"
                              sx={{
                                color: theme.palette.primary.main,
                                textAlign: "center",
                              }}
                              noWrap
                            >
                              Abonnés
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      {React.Children.toArray(
                        getCanalsWithEngagement().map((canal: any) => {
                          const canalData = getCanal(canal);
                          return (
                            <>
                              <Grid item xs={1}>
                                {canalData?.logo}
                              </Grid>
                              <Grid item xs={11}>
                                <CanalForm
                                  hideSubmit
                                  onValuesChange={(values) => {
                                    dispatch(setCalculatorCanal(canal, values));
                                    dispatch(setCanCalculate());
                                  }}
                                  initialValues={getInitialValues(canal)}
                                  disabledFields={
                                    CANAL_FIELDS_META?.[canal]?.disabledFields
                                  }
                                  hiddenFields={
                                    CANAL_FIELDS_META?.[canal]?.hiddenFields
                                  }
                                />
                              </Grid>
                            </>
                          );
                        })
                      )}
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={2}>
                <Card>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Typography
                              variant="subtitle1"
                              sx={{
                                color: theme.palette.primary.main,
                                textAlign: "center",
                              }}
                              noWrap
                            >
                              Engagement
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      {React.Children.toArray(
                        getCanalsWithEngagement().map((canal: any) => {
                          return (
                            <Grid item xs={12}>
                              <Box display="flex" height={40}>
                                <EngagementRate
                                  value={Math.min(
                                    (calulatorState[canal.toLowerCase()]
                                      ?.engagementRate ?? 0) * 100,
                                    100
                                  )}
                                  animated
                                />
                              </Box>
                            </Grid>
                          );
                        })
                      )}
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={2}>
                <Card>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Typography
                              variant="subtitle1"
                              sx={{
                                color: theme.palette.primary.main,
                                textAlign: "center",
                              }}
                              noWrap
                            >
                              Valeur
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      {React.Children.toArray(
                        getCanalsWithEngagement().map((canal: any) => {
                          return (
                            <Grid item xs={12}>
                              <Box
                                display="flex"
                                height={40}
                                textAlign="center"
                                justifyContent={"center"}
                                alignItems="center"
                              >
                                <Typography>
                                  <CountUp
                                    end={
                                      calulatorState[canal.toLowerCase()]
                                        ?.postValue ?? 0
                                    }
                                    duration={1}
                                  />{" "}
                                  €
                                </Typography>
                              </Box>
                            </Grid>
                          );
                        })
                      )}
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        )}

        {getCanalsWithLikes().length > 0 && (
          <Box my={1}>
            <Grid container spacing={2}>
              <Grid item xs={10}>
                <Card>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={1}></Grid>
                      <Grid item xs={11}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Typography
                              variant="subtitle1"
                              sx={{
                                color: theme.palette.primary.main,
                                textAlign: "center",
                              }}
                              noWrap
                            >
                              Abonnés / Écoutes
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      {React.Children.toArray(
                        getCanalsWithLikes().map((canal: any) => {
                          const canalData = getCanal(canal);
                          return (
                            <>
                              <Grid item xs={1}>
                                {canalData?.logo}
                              </Grid>
                              <Grid item xs={11}>
                                <CanalForm
                                  hideSubmit
                                  onValuesChange={(values) => {
                                    dispatch(setCalculatorCanal(canal, values));
                                    dispatch(setCanCalculate());
                                  }}
                                  initialValues={getInitialValues(canal)}
                                  disabledFields={
                                    CANAL_FIELDS_META?.[canal]?.disabledFields
                                  }
                                  hiddenFields={
                                    CANAL_FIELDS_META?.[canal]?.hiddenFields
                                  }
                                />
                              </Grid>
                            </>
                          );
                        })
                      )}
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={2}>
                <Card>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Typography
                              variant="subtitle1"
                              sx={{
                                color: theme.palette.primary.main,
                                textAlign: "center",
                              }}
                              noWrap
                            >
                              Valeur
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      {React.Children.toArray(
                        getCanalsWithLikes().map((canal: any) => {
                          return (
                            <Grid item xs={12}>
                              <Box
                                display="flex"
                                height={40}
                                textAlign="center"
                                justifyContent={"center"}
                                alignItems="center"
                              >
                                <Typography>
                                  <CountUp
                                    end={
                                      calulatorState[canal.toLowerCase()]
                                        ?.postValue ?? 0
                                    }
                                    duration={1}
                                  />{" "}
                                  €
                                </Typography>
                              </Box>
                            </Grid>
                          );
                        })
                      )}
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        )}

        {canals.length > 0 && (
          <>
            <Box my={4}>
              <Score {...getScoreData(score)} />
            </Box>

            <Box
              mt={4}
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              width="100%"
            >
              <Box mx={2} width={320}>
                <TextField
                  name="email"
                  type="email"
                  InputLabelProps={{ shrink: true }}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onFocus={() => setIsEmailFocused(true)}
                  onBlur={() => setIsEmailFocused(false)}
                  helperText={emailHelperText()}
                  fullWidth
                />
              </Box>

              <Box
                display="flex"
                justifyContent={{ xs: "center", sm: "flex-end" }}
              >
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    if (!canCalculate) return;
                    dispatch(launchCalculation());
                    dispatch(setCanCalculate());
                  }}
                  disabled={!canCalculate}
                >
                  Calculer
                </Button>
              </Box>
            </Box>
            {calulatorState?.error && (
              <Box textAlign="right" mt={1}>
                {calulatorState.error === "NETWORK_ERROR" && (
                  <Typography
                    sx={{
                      color: purple[600],
                      fontStyle: "italic",
                      fontSize: "0.8rem",
                    }}
                  >
                    Nous rencontrons des problèmes lors du calcul.
                    <br /> Merci de ré-essayer ultérieurement.
                  </Typography>
                )}
                {["NO_EMAIL", "NOT_AN_EMAIL"].includes(
                  calulatorState.error
                ) && (
                  <Typography
                    sx={{
                      color: purple[600],
                      fontStyle: "italic",
                      fontSize: "0.8rem",
                    }}
                  >
                    Veuillez renseigner une adresse email valide.
                  </Typography>
                )}
              </Box>
            )}
          </>
        )}
      </Bubble>
    </Box>
  );
};
