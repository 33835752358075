import React, { useCallback } from "react";

import { Button, Grid, Hidden, useMediaQuery, useTheme } from "@mui/material";
import { TextField } from "mui-rff";
import { Form, FormSpy } from "react-final-form";
import { grey } from "@mui/material/colors";

export type CanalFormValues = {
  views?: string;
  likes?: string;
  comments?: string;
  pageLikes?: string;
  listeners?: string;
};

export type CanalFormData = {
  views?: number;
  likes?: number;
  comments?: number;
  pageLikes?: number;
  listeners?: number;
};

const toNumberValue = (value?: string) =>
  !isNaN(+(value ?? 0)) ? +(value ?? 0) : 0;

export interface CanalFormProps {
  initialValues?: CanalFormValues;
  buttonRef?: React.Ref<HTMLButtonElement>;
  hideSubmit?: boolean;
  disabledFields?: string[];
  hiddenFields?: string[];
  onSubmit?: (values: CanalFormData) => void;
  onValuesChange?: (values: CanalFormData) => void;
}

const FIELDS = [
  ["views", "Vues"],
  ["likes", "Likes"],
  ["comments", "Commentaires"],
  ["pageLikes", "Abonnés"],
];

export const CanalForm = ({
  initialValues,
  buttonRef,
  hideSubmit,
  disabledFields,
  hiddenFields,
  onSubmit,
  onValuesChange,
}: CanalFormProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const fields = useCallback(() => {
    if (isMobile) {
      const _hiddenFields = [
        ...(hiddenFields ?? []),
        ...(disabledFields ?? []),
      ];
      return FIELDS.filter(
        ([field]) => !_hiddenFields?.length || !_hiddenFields?.includes(field)
      );
    }
    return FIELDS.filter(
      ([field]) => !hiddenFields?.length || !hiddenFields?.includes(field)
    );
  }, [hiddenFields, disabledFields, isMobile]);

  return (
    <Form
      {...{ initialValues }}
      onSubmit={(values: CanalFormValues) => {
        const { views, likes, comments, pageLikes } = values;
        onSubmit?.({
          views: toNumberValue(views),
          likes: toNumberValue(likes),
          comments: toNumberValue(comments),
          pageLikes: toNumberValue(pageLikes),
          listeners: toNumberValue(pageLikes),
        });
      }}
      validate={(values: CanalFormValues) => {
        const errors: any = {};
        const { views, likes, comments, pageLikes } = values;
        [
          ["views", views],
          ["likes", likes],
          ["comments", comments],
          ["pageLikes", pageLikes],
        ].forEach(([field, value]) => {
          if (field && !!value && isNaN(+value)) {
            errors[field] = "Valeur invalide";
          }
        });
        return errors;
      }}
      render={({ handleSubmit, errors }) => (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(e);
          }}
        >
          {onValuesChange && (
            <FormSpy
              subscription={{ values: true, active: true }}
              onChange={({ values, active }) => {
                if (!active && !!values && Object.keys(values).length > 0) {
                  onValuesChange(values);
                }
              }}
            />
          )}
          <Grid container spacing={2}>
            <Hidden mdDown>
              {fields().map(([field, label]) => (
                <Grid
                  item
                  xs={(12 / (fields().length ?? 1)) as any}
                  key={field}
                >
                  <TextField
                    name={field}
                    inputProps={{
                      sx: {
                        textAlign: "center",
                      },
                      pattern: "[0-9]*",
                      "aria-label": label,
                    }}
                    disabled={disabledFields?.includes(field)}
                    error={!!errors?.[field]}
                    variant="standard"
                    sx={{
                      "& .Mui-disabled": {
                        background: grey[100],
                      },
                    }}
                  />
                </Grid>
              ))}
            </Hidden>
            <Hidden mdUp>
              {fields().map(([field, label]) => (
                <Grid item xs={12} sm={6} key={field}>
                  <TextField
                    label={label}
                    name={field}
                    inputProps={{
                      sx: {
                        textAlign: "center",
                      },
                      pattern: "[0-9]*",
                      "aria-label": label,
                      "aria-labelledby": `label-${field}`,
                    }}
                    disabled={disabledFields?.includes(field)}
                    error={!!errors?.[field]}
                    variant="standard"
                  />
                </Grid>
              ))}
            </Hidden>
          </Grid>
          {!hideSubmit && <Button type="submit">Valider</Button>}
          {buttonRef && (
            <button
              type="submit"
              ref={buttonRef}
              style={{ visibility: "hidden" }}
            />
          )}
        </form>
      )}
    />
  );
};
