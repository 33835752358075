import { createTheme, ThemeOptions } from "@mui/material";

export const primaryColor = "#827DF3";
export const primaryColorDark = "#1C0F7D";
export const primaryColorLight = "#6B5AED";

export const secondaryColor = "#0FC7BD";
export const secondaryColorDark = "#005F5A";
export const secondaryColorLight = "#48E2DB";

export const primaryColorText = "#5040CD";
export const secondaryColorText = "#5040CD";
export const hintColorText = "#8798a0";

export const commonBlack = "#12121e";

const theme = createTheme();

export const themeObject: ThemeOptions = {
  palette: {
    common: {
      black: commonBlack,
      white: "#ffffff",
    },
    text: {
      primary: primaryColorText,
      secondary: secondaryColorText,
    },
    primary: {
      light: primaryColorLight,
      main: primaryColor,
      dark: primaryColorDark,
      contrastText: "#ffffff",
    },
    secondary: {
      light: secondaryColorLight,
      main: secondaryColor,
      dark: secondaryColorDark,
      contrastText: "#ffffff",
    },

    background: {
      default: "#ffffff",
    },
  },
  typography: {
    fontFamily: 'Inter, "Helvetica Neue", Arial, sans-serif',
    fontSize: 16,
    htmlFontSize: 16,
    h1: {
      fontSize: 24,
    },
    h2: {
      fontSize: 20,
    },
    h3: {
      fontWeight: "bold",
      fontSize: 18,
      textTransform: "uppercase",
    },
    h4: {
      fontWeight: "bold",
      fontSize: 16,
    },
    h5: {
      fontWeight: "bold",
      fontSize: 14,
    },
    h6: {
      fontWeight: "bold",
      fontSize: 14,
    },
    subtitle1: {
      fontWeight: "bold",
      fontSize: 14,
    },
    subtitle2: {
      fontWeight: "normal",
      fontSize: 14,
    },
    body1: {
      fontSize: 14,
    },
    body2: {
      fontSize: 14,
    },
    caption: {
      fontWeight: "normal",
      fontSize: 12,
    },
    button: {
      fontWeight: "normal",
      fontSize: 14,
    },
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 22,
          boxShadow:
            "-4px -4px 8px rgba(219, 212, 212, 0.25), 4px 4px 8px rgba(219, 212, 212, 0.35);",
          padding: "6px 24px",
        },
        text: {
          boxShadow: "none",
          color: commonBlack,
          textTransform: "none",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: "transparent",
          boxShadow: "none",
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          cursor: "pointer",
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          color: hintColorText,
          fontWeight: "bold",
          fontSize: "0.75rem",
          textTransform: "uppercase",
          [theme.breakpoints.down("xs")]: {
            fontWeight: "normal",
          },
        },
        active: {
          fontWeight: "bold !important" as "bold",
          color: `${secondaryColor} !important`,
        },
        completed: {
          fontWeight: "bold !important" as "bold",
          color: `${hintColorText} !important`,
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: primaryColorText,
          "& svg:first-child": {
            color: primaryColorText,
          },
          "&:checked": {
            color: primaryColorText,
          },
          padding: 8,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          "& svg": {
            color: primaryColorText,
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: "#bdbdbd",
          fontSize: "1.125rem",
          fontWeight: "normal",
          lineHeight: "0.8rem",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          fontSize: "1.125rem",
          fontWeight: "normal",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          height: 2,
          margin: 8,
          backgroundColor: primaryColorText,
        },
        light: {
          height: "1px",
          margin: "auto 0",
          backgroundColor: primaryColorText,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 15,
          filter: "drop-shadow(4px 4px 8px rgba(219, 212, 212, 0.25));",
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          color: commonBlack,
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          color: commonBlack,
          padding: 16,
          paddingBottom: 16,
        },
      },
    },
    MuiMobileStepper: {
      styleOverrides: {
        root: {
          background: "white",
          justifyContent: "center",
        },
      },
    },
  },
};

const compiledTheme = createTheme(themeObject);

export default compiledTheme;
