import { AnyAction, Dispatch } from 'redux';
import { calculateCompanyScore } from '../../axios/calculator.api';
import {
  CalculatorStatus,
  Canal,
  CanalData,
} from '../../const/calculator.const';

export enum CalculatorAction {
  SET_ID = 'SET_ID',
  SET_NAME = 'SET_NAME',
  SET_LOGO = 'SET_LOGO',
  SET_CANAL = 'SET_CANAL',
  SET_SCORE = 'SET_SCORE',
  SET_RATE = 'SET_RATE',
  SET_STATUS = 'SET_STATUS',
  SET_ERROR = 'SET_ERROR',
  SET_CANALS = 'SET_CANALS',
  SET_EMAIL = 'SET_EMAIL',
  SET_CAN_CALCULATE = 'SET_CAN_CALCULATE',
}

export const isEmail = (email: string): boolean => {
  const re =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const setCanCalculate =
  () => (dispatch: Dispatch<AnyAction>, getState: () => any) => {
    let canCalculate = false;
    let emailIsMissing = true;
    let allCanalOk = false;

    const { calculator } = getState();
    const { email, canals, status } = calculator;

    emailIsMissing = !email || !isEmail(email);
    allCanalOk = !!canals?.length;

    if (allCanalOk && !!canals?.length) {
      allCanalOk = canals.every(
        (name: string) =>
          calculator[name.toLowerCase()] &&
          ['views', 'likes', 'comments', 'pageLikes'].every(
            (field) =>
              !calculator[name.toLowerCase()][field] ||
              !isNaN(+calculator[name.toLowerCase()][field])
          )
      );

      if (allCanalOk) {
        allCanalOk = canals.some(
          (name: string) =>
            calculator[name.toLowerCase()] &&
            ['views', 'likes', 'comments', 'pageLikes'].some(
              (field) => +calculator[name.toLowerCase()][field] > 0
            )
        );
      }
    }

    canCalculate =
      allCanalOk && !emailIsMissing && status !== CalculatorStatus.CALCULATING;

    dispatch({
      type: CalculatorAction.SET_CAN_CALCULATE,
      payload: {
        canCalculate,
        emailIsMissing,
        canalStatsIsMissing: !allCanalOk,
      },
    });
  };

export const setCalculatorId = () => (dispatch: Dispatch<AnyAction>) => {
  dispatch({
    type: CalculatorAction.SET_ID,
  });
};

export const setCalculatorCanals =
  (canals: Canal[]) => (dispatch: Dispatch<AnyAction>) => {
    dispatch({
      type: CalculatorAction.SET_CANALS,
      payload: { canals },
    });
  };

export const setCalculatorCanal =
  (canal: Canal, values: CanalData) => (dispatch: Dispatch<AnyAction>) => {
    const valuesAsNumber = Object.fromEntries(
      Object.entries(values).map(([key, value]) => {
        if (isNaN(+value) || value === '') return [key, 0];
        return [key, +value];
      })
    );
    dispatch({
      type: CalculatorAction.SET_CANAL,
      payload: { canal, values: valuesAsNumber },
    });
  };

export const setCalculatorCanalScore =
  (canal: Canal, score: number) => (dispatch: Dispatch<AnyAction>) => {
    dispatch({
      type: CalculatorAction.SET_SCORE,
      payload: { canal, score },
    });
  };

export const setCalculatorCanalEngagementRate =
  (canal: Canal, rate: number) => (dispatch: Dispatch<AnyAction>) => {
    dispatch({
      type: CalculatorAction.SET_RATE,
      payload: { canal, rate },
    });
  };

export const setCalculatorStatus =
  (status: CalculatorStatus) => (dispatch: Dispatch<AnyAction>) => {
    dispatch({
      type: CalculatorAction.SET_STATUS,
      payload: { status },
    });
  };

export const launchCalculation =
  () => async (dispatch: Dispatch<AnyAction>, getState: () => any) => {
    dispatch({
      type: CalculatorAction.SET_ERROR,
      payload: {
        error: null,
      },
    });
    const { calculator } = getState();
    const { status, id, email, canals } = calculator ?? {};
    if (status === CalculatorStatus.CALCULATING) return;

    if (!email) {
      dispatch({
        type: CalculatorAction.SET_ERROR,
        payload: {
          error: 'NO_EMAIL',
        },
      });
      return;
    }

    if (!isEmail(email)) {
      dispatch({
        type: CalculatorAction.SET_ERROR,
        payload: {
          error: 'NOT_AN_EMAIL',
        },
      });
      return;
    }
    dispatch({
      type: CalculatorAction.SET_STATUS,
      payload: {
        status: CalculatorStatus.CALCULATING,
      },
    });

    const options: Record<string, any> = {
      id,
    };
    canals.forEach((canal: Canal) => {
      if (calculator[canal.toLowerCase()]) {
        options[canal.toLowerCase()] = calculator[canal.toLowerCase()];
        options[canal.toLowerCase()].listeners =
          calculator[canal.toLowerCase()].pageLikes;
      }
    });
    try {
      const { data } = await calculateCompanyScore(email, options);
      if (data?.id) {
        dispatch({
          type: CalculatorAction.SET_ID,
          payload: { id: data.id },
        });
      }
      let canalWithScoreCount = 0;
      let engagementRateSum = 0;
      canals.forEach((canal: Canal) => {
        //@ts-ignore
        const values: Record<string, string> = data[canal.toLowerCase()];
        if (values) {
          const { postValue, engagementRate } = values;
          dispatch({
            type: CalculatorAction.SET_CANAL,
            payload: {
              canal,
              values: { postValue, engagementRate },
            },
          });
          if (+engagementRate > 0) {
            engagementRateSum += +engagementRate;
            canalWithScoreCount++;
          }
        }
      });
      if (canalWithScoreCount > 0) {
        dispatch({
          type: CalculatorAction.SET_SCORE,
          payload: {
            score: (engagementRateSum / canalWithScoreCount) * 100,
          },
        });
      } else {
        dispatch({
          type: CalculatorAction.SET_SCORE,
          payload: {
            score: 0,
          },
        });
      }
    } catch (e) {
      dispatch({
        type: CalculatorAction.SET_ERROR,
        payload: {
          error: 'NETWORK_ERROR',
        },
      });
    } finally {
      dispatch({
        type: CalculatorAction.SET_STATUS,
        payload: {
          status: CalculatorStatus.CALCULATED,
        },
      });
    }
  };

export const setCalculatorEmail =
  (email: string) => (dispatch: Dispatch<AnyAction>) => {
    dispatch({
      type: CalculatorAction.SET_EMAIL,
      payload: { email },
    });
  };
