export enum CalculatorStatus {
  INITIAL = "INITIAL",
  CALCULATING = "CALCULATING",
  CALCULATED = "CALCULATED",
}

export type Canal =
  | "facebook"
  | "instagram"
  | "twitter"
  | "linkedin"
  | "youtube"
  | "tiktok"
  | "clubhouse"
  | "pinterest"
  | "snapchat"
  | "twitch"
  | "podcast";

export const CANALS = [
  "facebook",
  "instagram",
  "twitter",
  "linkedin",
  "youtube",
  "tiktok",
  "clubhouse",
  "pinterest",
  "snapchat",
  "twitch",
  "podcast",
];

export const CANALS_WITH_ENGAGEMENT = [
  "facebook",
  "instagram",
  "twitter",
  "linkedin",
  "youtube",
  "tiktok",
  "pinterest",
  "snapchat",
  "twitch",
];

export const CANALS_ONLY_LIKE = ["clubhouse", "podcast"];

export interface CanalData {
  views?: number;
  likes?: number;
  comments?: number;
  pageLikes?: number;
}

export const CANAL_FIELDS_META: any = {
  Clubhouse: {
    hiddenFields: ["views", "likes", "comments"],
  },
  Podcast: {
    hiddenFields: ["views", "likes", "comments"],
  },
};
