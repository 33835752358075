import { SvgIconProps } from './SvgIcon';

export const Instagram = ({ size = 48 }: SvgIconProps) => {
  return (
    <svg
      id='instagram'
      enableBackground='new 0 0 512 512'
      viewBox='0 0 512 512'
      width={size}
      height={size}
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <linearGradient
        gradientUnits='userSpaceOnUse'
        id='GradientInsta'
        x1='407.1325'
        x2='104.8675'
        y1='4.4733'
        y2='507.5267'
      >
        <stop offset='0' stopColor='#4845A2' />
        <stop offset='4.536461e-02' stopColor='#5945A2' />
        <stop offset='0.1713' stopColor='#8444A1' />
        <stop offset='0.2698' stopColor='#9E44A1' />
        <stop offset='0.3282' stopColor='#A844A1' />
        <stop offset='0.3905' stopColor='#AB429A' />
        <stop offset='0.4788' stopColor='#B43C88' />
        <stop offset='0.4896' stopColor='#B53B85' />
        <stop offset='0.5577' stopColor='#BE3572' />
        <stop offset='0.6762' stopColor='#D22749' />
        <stop offset='0.7388' stopColor='#DF4F3E' />
        <stop offset='0.8559' stopColor='#F9A326' />
        <stop offset='1' stopColor='#F9DD26' />
      </linearGradient>
      <path
        fill='url(#GradientInsta)'
        d='M391.5,13H120.5C61.1,13,13,61.1,13,120.5v270.9C13,450.9,61.1,499,120.5,499h270.9   c59.4,0,107.5-48.1,107.5-107.5V120.5C499,61.1,450.9,13,391.5,13z'
      />
      <circle fill='#fff' cx='352.4' cy='160' r='21.2' />
      <g>
        <path
          fill='#fff'
          d='M333.8,76H178.2C121.8,76,76,121.8,76,178.2v155.6C76,390.2,121.8,436,178.2,436h155.6    c56.4,0,102.2-45.9,102.2-102.2V178.2C436,121.8,390.2,76,333.8,76z M403.5,333.8c0,38.4-31.3,69.7-69.7,69.7H178.2    c-38.4,0-69.7-31.3-69.7-69.7V178.2c0-38.4,31.3-69.7,69.7-69.7h155.6c38.4,0,69.7,31.3,69.7,69.7V333.8z'
        />
        <path
          fill='#fff'
          d='M256,162.3c-51.7,0-93.7,42-93.7,93.7s42,93.7,93.7,93.7s93.7-42,93.7-93.7S307.7,162.3,256,162.3z     M256,317.2c-33.7,0-61.2-27.5-61.2-61.2s27.5-61.2,61.2-61.2s61.2,27.5,61.2,61.2S289.7,317.2,256,317.2z'
        />
      </g>
    </svg>
  );
};
