import { useState } from "react";
import {
  Box,
  Hidden,
  IconButton,
  Typography,
  Collapse,
  Grid,
  Button,
  Tooltip,
} from "@mui/material";
import { CANALS, CanalName } from "./canals";
import { ChevronUp, ChevronDown } from "react-feather";

export interface CanalPickerProps {
  selectedCanals?: CanalName[];
  onClose?: (selectedCanals: CanalName[]) => void;
}

export const CanalPicker = ({ selectedCanals, onClose }: CanalPickerProps) => {
  const [isOpen, setIsOpen] = useState(selectedCanals?.length === 0 ?? true);
  return (
    <>
      <Hidden mdDown>
        <Box display="flex" alignItems="center" width="100%">
          <Box display="flex" alignItems="center" color="primary.main">
            <Typography variant="subtitle1" noWrap>
              Vos réseaux :
            </Typography>
          </Box>
          <Box mx={1} flexGrow={1} display="flex">
            {CANALS.filter(({ enabled }) => !!enabled).map((canal) => (
              <Box mr={1} key={canal.name}>
                <Tooltip title={canal.name}>
                  <IconButton
                    onClick={() => {
                      if (selectedCanals?.includes(canal.name)) {
                        onClose?.(
                          selectedCanals.filter((name) => canal.name !== name)
                        );
                      } else {
                        onClose?.([...(selectedCanals ?? []), canal.name]);
                      }
                    }}
                    style={{
                      opacity: selectedCanals?.includes(canal.name) ? 1 : 0.3,
                    }}
                  >
                    {canal.logo}
                  </IconButton>
                </Tooltip>
              </Box>
            ))}
          </Box>
        </Box>
      </Hidden>
      <Hidden mdUp>
        <Box alignItems="center">
          <Box display="flex" alignItems="center" color="primary.main" mb={2}>
            <Button
              size="small"
              onClick={() => setIsOpen((current) => !current)}
            >
              <Typography variant="subtitle1" noWrap>
                Sélectionnez vos réseaux{" "}
              </Typography>{" "}
              {isOpen ? <ChevronUp /> : <ChevronDown />}
            </Button>
          </Box>
          <Collapse in={isOpen || !selectedCanals?.length}>
            <Box textAlign="center">
              <Grid container spacing={1}>
                {CANALS.filter((canal) => canal.enabled).map((canal) => (
                  <Grid item xs={3} md={2} sm={1} key={canal.name}>
                    <IconButton
                      onClick={() => {
                        if (selectedCanals?.includes(canal.name)) {
                          onClose?.(
                            selectedCanals?.filter(
                              (name) => canal.name !== name
                            )
                          );
                        } else {
                          onClose?.([...(selectedCanals ?? []), canal.name]);
                        }
                      }}
                      style={{
                        opacity: selectedCanals?.includes(canal.name) ? 1 : 0.3,
                      }}
                    >
                      {canal.logo}
                    </IconButton>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Collapse>
        </Box>
      </Hidden>
    </>
  );
};
