import React, { useEffect, useRef, useState } from "react";
import { version } from "../../../package.json";

import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  Grid,
  TextField,
  CardHeader,
  Collapse,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import { CanalPicker } from "../../components/Canal/CanalPicker";
import { Bubble } from "../Bubble/Bubble";
import { CanalForm } from "../../components/Form/CanalForm";
import { EngagementRate } from "../../components/Result/EngagementRate";
import { useSelector, useDispatch } from "react-redux";
import {
  setCalculatorCanals,
  setCalculatorCanal,
  launchCalculation,
  setCalculatorStatus,
  setCalculatorEmail,
  setCanCalculate,
} from "../../redux/actions/calculator";
import {
  CalculatorStatus,
  CANAL_FIELDS_META,
} from "../../const/calculator.const";
import isEqual from "react-fast-compare";
import { BrandySVG } from "../../components/images/BrandySVG";
import { Score } from "../../components/Score/Score";
import CountUp from "react-countup";
import { useDebounce } from "use-debounce";
import { grey, purple } from "@mui/material/colors";
import { getCanal } from "../../components/Canal/canals";
import { ChevronDown, ChevronUp } from "react-feather";

export interface MobileCalculatorProps {
  getScoreData: (score?: number) => any;
}

export const MobileCalculator = ({ getScoreData }: MobileCalculatorProps) => {
  const [open, setOpen] = useState(true);
  const resultRef = useRef<any>();

  const calulatorState = useSelector<any, any>(
    (state) => state.calculator,
    isEqual
  );
  const {
    canals,
    status,
    score,
    email: storeEmail,
    canCalculate,
    emailIsMissing,
    canalStatsIsMissing,
    canalUpdated,
  } = calulatorState;
  const dispatch = useDispatch();
  const [email, setEmail] = useState(storeEmail);
  const [debouncedEmail] = useDebounce(email, 300);
  const [isEmailFocused, setIsEmailFocused] = useState(false);

  useEffect(() => {
    if (!isEmailFocused && storeEmail !== email) {
      setEmail(storeEmail);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeEmail]);

  useEffect(() => {
    dispatch(setCalculatorEmail(debouncedEmail));
    dispatch(setCanCalculate());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedEmail, canalUpdated]);

  useEffect(() => {
    if (status === CalculatorStatus.CALCULATING) {
      dispatch(setCalculatorStatus(CalculatorStatus.INITIAL));
    }
    if (status === CalculatorStatus.CALCULATED) {
      if (resultRef?.current?.offsetTop) {
        window.scrollTo({
          top: resultRef.current.offsetTop,
          behavior: "smooth",
        });
      }
    }
    dispatch(setCanCalculate());
  }, [dispatch, status]);

  const helperText = () => {
    if (!canals?.length) return "Sélectionnez vos réseaux";

    if (canalStatsIsMissing) return "Renseignez vos chiffres";

    if (emailIsMissing)
      return "Saisissez votre adresse email pour lancer le calcul";

    if (canCalculate) return "Calculez la valeur de votre contenu !";

    return "";
  };

  const scoreData = getScoreData(score);

  const getInitialValues = (name: string) => {
    if (calulatorState[name.toLowerCase()])
      return Object.fromEntries(
        Object.entries(calulatorState[name.toLowerCase()]).map(
          ([key, value]) => {
            const strValue = value as string;
            if (isNaN(+strValue) || +strValue === 0) return [key, ""];
            return [key, value];
          }
        )
      );
    return {};
  };

  return (
    <>
      <Box px={1}>
        <Bubble>
          <Box position="absolute" top={-40} right={0}>
            <BrandySVG />
          </Box>

          <Box my={2}>
            <CanalPicker
              selectedCanals={canals}
              onClose={(canals) =>
                dispatch(setCalculatorCanals(canals as any[]))
              }
            />
          </Box>

          {canals.length > 0 && (
            <>
              <Box my={4}>
                <Grid container spacing={4}>
                  {canals.map((name: any) => {
                    const canalData = getCanal(name);
                    return (
                      <Grid item xs={12} key={name}>
                        <Card>
                          <CardHeader avatar={canalData?.logo} title={name} />
                          <CardContent>
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <CanalForm
                                  hideSubmit
                                  onValuesChange={(values) => {
                                    dispatch(setCalculatorCanal(name, values));
                                  }}
                                  initialValues={getInitialValues(name)}
                                  disabledFields={
                                    CANAL_FIELDS_META?.[name]?.disabledFields
                                  }
                                  hiddenFields={
                                    CANAL_FIELDS_META?.[name]?.hiddenFields
                                  }
                                />
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            </>
          )}
        </Bubble>
      </Box>

      <Collapse in={canals.length > 0}>
        <Box px={1} mt={2} ref={resultRef}>
          <Bubble>
            <Box my={4}>
              <List dense>
                {canals.map((name: any) => {
                  const canalData = getCanal(name);
                  return (
                    <ListItem key={name}>
                      <ListItemIcon>{canalData?.logo}</ListItemIcon>
                      <ListItemText>
                        <Grid container spacing={4}>
                          <Grid item xs={6}>
                            <Typography
                              sx={{
                                fontSize: 12,
                                fontWeight: 500,
                                color: grey[500],
                                textTransform: "uppercase",
                              }}
                            >
                              Engagement
                            </Typography>
                            <Typography>
                              {calulatorState[name.toLowerCase()]
                                ?.engagementRate ? (
                                <EngagementRate
                                  value={Math.min(
                                    (calulatorState[name.toLowerCase()]
                                      ?.engagementRate ?? 0) * 100,
                                    100
                                  )}
                                  animated
                                />
                              ) : (
                                <Typography
                                  sx={{ color: grey[800] }}
                                  variant="caption"
                                >
                                  N/A
                                </Typography>
                              )}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography
                              sx={{
                                fontSize: 12,
                                fontWeight: 500,
                                color: grey[500],
                                textTransform: "uppercase",
                              }}
                            >
                              Valeur
                            </Typography>
                            <Typography sx={{ color: grey[900] }}>
                              {calulatorState[name.toLowerCase()]?.postValue ? (
                                <Typography sx={{ color: grey[800] }}>
                                  <CountUp
                                    end={
                                      calulatorState[name.toLowerCase()]
                                        ?.postValue ?? 0
                                    }
                                    duration={1}
                                  />{" "}
                                  €
                                </Typography>
                              ) : (
                                <Typography
                                  sx={{ color: grey[800] }}
                                  variant="caption"
                                >
                                  0€
                                </Typography>
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                      </ListItemText>
                    </ListItem>
                  );
                })}
              </List>
            </Box>
          </Bubble>
        </Box>
      </Collapse>

      <Box
        width="100vw"
        bgcolor="white"
        position="fixed"
        bottom={0}
        zIndex={10}
        ml={{ xs: -2, sm: -3 }}
        p={2}
        pb={1}
        boxSizing="border-box"
        boxShadow="0px -2px 4px lightgrey"
      >
        <Box textAlign={"center"}>
          <Button
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignContent: "center",
            }}
            fullWidth
            size="small"
            onClick={() =>
              setOpen((current) => {
                if (!canals.length || !scoreData?.evaluation) return true;
                return !current;
              })
            }
          >
            <div />
            {helperText()}
            {!!canals.length && scoreData?.evaluation ? (
              !open ? (
                <ChevronUp />
              ) : (
                <ChevronDown />
              )
            ) : (
              <div />
            )}
          </Button>
        </Box>

        <Collapse in={!!canals.length && open}>
          <Box my={2} width="90%" maxWidth={350} mx="auto">
            <TextField
              name="email"
              type="email"
              InputLabelProps={{ shrink: true }}
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onFocus={() => setIsEmailFocused(true)}
              onBlur={() => setIsEmailFocused(false)}
              fullWidth
            />
          </Box>

          <Box
            mt={2}
            display="flex"
            alignItems="center"
            width="100%"
            flexDirection="column"
          >
            <Box flexGrow={1} width="100%"></Box>

            <Box
              display="flex"
              justifyContent={{ xs: "center", sm: "flex-end" }}
            >
              <Button
                color="primary"
                variant="contained"
                onClick={(e) => {
                  dispatch(launchCalculation());
                  //@ts-ignore
                  e.target.blur();
                  const element = document.getElementById("calculator");
                  if (element) {
                    window.scrollTo({
                      top: element.offsetTop,
                      behavior: "smooth",
                    });
                  }
                }}
                disabled={!canCalculate}
              >
                Calculer
              </Button>
            </Box>
          </Box>
          {calulatorState?.error && (
            <Box textAlign="center" mt={2}>
              {calulatorState.error === "NETWORK_ERROR" && (
                <Typography
                  sx={{
                    color: purple[600],
                    fontStyle: "italic",
                    fontSize: "0.8rem",
                  }}
                >
                  Nous rencontrons des problèmes lors du calcul.
                  <br /> Merci de ré-essayer ultérieurement.
                </Typography>
              )}
              {["NO_EMAIL", "NOT_AN_EMAIL"].includes(calulatorState.error) && (
                <Typography
                  sx={{
                    color: purple[600],
                    fontStyle: "italic",
                    fontSize: "0.8rem",
                  }}
                >
                  Veuillez renseigner une adresse email valide.
                </Typography>
              )}
            </Box>
          )}

          <div id="score">
            <Box my={2}>
              <Score {...scoreData} />
            </Box>
          </div>
        </Collapse>

        <Box mt={2} color="primary.main" textAlign={"center"}>
          <Typography variant="caption">
            Reach Calculator v{version} | © Reachmaker{" "}
            {new Date().getFullYear()}
          </Typography>
        </Box>
      </Box>
    </>
  );
};
