import axios from 'axios';

const baseURL = process.env.REACT_APP_CALCULATOR_API;
const options = {
  baseURL,
};
const instance = axios.create(options);

export const { get, post, patch, delete: del } = instance;

export default instance;
