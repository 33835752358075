import { Hidden } from "@mui/material";
import React from "react";
import { DesktopCalculator } from "./DesktopCalculator";
import { MobileCalculator } from "./MobileCalculator";

export interface CalculatorProps {}

const getScoreData = (score?: number) => {
  if (!score) return;
  if (score >= 1) {
    return {
      evaluation: "A+",
      icon: <img src="/score-ap.png" alt="Score A+" />,
      text: "Waouh, votre communauté est ultra engagée. Il ne vous reste plus qu’à trouver le partenaire idéal.",
    };
  }
  if (score >= 0.5) {
    return {
      evaluation: "A",
      icon: <img src="/score-a.png" alt="Score A+" />,
      text: "Excellent score ! Passez à l’étape supérieure avec le partenaire adapté.",
    };
  }
  if (score >= 0.2) {
    return {
      evaluation: "B+",
      icon: <img src="/score-bp.png" alt="Score A+" />,
      text: "Pas mal ! Dopez votre visibilité avec une partenaire sur-mesure !",
    };
  }
  return {
    evaluation: "B",
    icon: <img src="/score-b.png" alt="Score B" />,
    text: "C’est le début ! Vous avez toutes les cartes en main pour exploser. Matchez le bon partenaire sur Reachmaker.",
  };
};

export const Calculator = (_: CalculatorProps) => {
  return (
    <>
      <Hidden mdDown>
        <DesktopCalculator {...{ getScoreData }} />
      </Hidden>
      <Hidden mdUp>
        <MobileCalculator {...{ getScoreData }} />
      </Hidden>
    </>
  );
};
