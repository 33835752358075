import { AnyAction } from 'redux';
import { CANALS, CalculatorStatus } from '../../const/calculator.const';
import { CalculatorAction } from '../actions/calculator';

const createInitialStats = () => ({
  views: 0,
  likes: 0,
  comments: 0,
  pageLikes: 0,
  engagementRate: 0,
  postValue: 0,
});

const initialState = {
  id: '',
  email: '',
  score: 0,
  status: CalculatorStatus.INITIAL,
  error: null,
  canals: [],
  canCalculate: false,
  emailIsMissing: true,
  canalStatsIsMissing: true,
  canalUpdated: 0,
};

CANALS.forEach((canal) => {
  //@ts-ignore
  initialState[canal] = createInitialStats();
});

const calculatorReducer = (state = initialState, action: AnyAction) => {
  const { payload } = action ?? { payload: {} };
  const {
    id,
    canal,
    values,
    score,
    status,
    error,
    canals,
    email,
    canCalculate,
    emailIsMissing,
    canalStatsIsMissing,
  } = payload ?? {};
  const canalKey = canal?.toLowerCase();
  const currentValues = canalKey
    ? //@ts-ignore
      state[canalKey] ?? createInitialStats()
    : undefined;

  switch (action.type) {
    case CalculatorAction.SET_ID:
      return { ...state, id };
    case CalculatorAction.SET_CANAL:
      return {
        ...state,
        [canalKey]: { ...currentValues, ...values },
        canalUpdated: state.canalUpdated + 1,
      };
    case CalculatorAction.SET_SCORE:
      return { ...state, score };
    case CalculatorAction.SET_STATUS:
      return { ...state, status };
    case CalculatorAction.SET_ERROR:
      return { ...state, error };
    case CalculatorAction.SET_CANALS:
      return { ...state, canals };
    case CalculatorAction.SET_EMAIL:
      return { ...state, email: email ?? '' };
    case CalculatorAction.SET_CAN_CALCULATE:
      return {
        ...state,
        canCalculate: !!canCalculate,
        emailIsMissing,
        canalStatsIsMissing,
      };
    default:
      return state;
  }
};

export default calculatorReducer;
