import React, { useState } from "react";

import {
  Grid,
  Typography,
  Box,
  Button,
  Collapse,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  useTheme,
} from "@mui/material";
import { ChevronDown, ChevronUp } from "react-feather";
import { Bubble } from "../Bubble/Bubble";
import { Youtube } from "../../components/icons";

export interface FaqProps {}

const FAQ = [
  {
    title: "Comment ça marche ?",
    text: [
      "1. Sélectionnez les réseaux que vous souhaitez évaluer",
      "2. Saisissez sur la base d'un post, tweet ou vidéo, les valeurs moyennes de vues, likes & commentaires (si applicable)",
      "3. Saisissez le nombre total de vos abonnés par canal",
      "4. Entrez votre adresse email et valider le calcul",
    ],
    link: {
      text: "Voir la démo sur Youtube",
      href: "https://youtu.be/ef6Dzt3NMzI",
    },
  },
  {
    title: "Quelles sont vos sources ?",
    text: [
      "Nos données s’appuient sur le Earned Media Value Index, Ayzenberg Index.",
      "Et l’analyse croisée de milliers de comptes sociaux.",
    ],
  },
  {
    title: "Traitement des données",
    text: [
      "Les données que vous transmettez à Reachmaker sont enregistrées afin améliorer notre algorithme.",
      "Votre adresse email et vos données ne seront jamais cédées à un tiers.",
      "Vous pouvez demander la suppression de vos données à tout moment.",
    ],
  },
  {
    title: "Conditions d'utilisation",
    text: [
      "Reach Calculator© est un service 100% gratuit. Offert par Reachmaker SAS.",
      "Il n'y pas de limite du nombre de calcul possible.",
      "Son utilisation est exclusivement réservée à l'usage du site internet https://calculator.reachmaker.com",
      "Reachmaker se réserve le droit de bloquer tout usage jugé frauduleux de son API",
    ],
  },
];

export const Faq = (_: FaqProps) => {
  const theme = useTheme();
  const [open, setOpen] = useState<string[]>([]);

  return (
    <Box
      sx={{
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(53),
        [theme.breakpoints.up("md")]: {
          paddingLeft: theme.spacing(4),
          paddingRight: theme.spacing(4),
          paddingBottom: theme.spacing(5),
        },
      }}
    >
      <Bubble>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box textAlign="center">
              <Typography component="h2" variant="h2">
                F. A. Q.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <List>
              {FAQ.map(({ title, text, link }, index, all) => {
                const isOpen = open.includes(title);
                const isLast = index === all.length - 1;
                const onClick = () => {
                  if (isOpen) {
                    setOpen((current) => current.filter((id) => id !== title));
                  } else {
                    setOpen((current) => [...current, title]);
                  }
                };
                return (
                  <ListItem
                    key={title}
                    button
                    onClick={onClick}
                    divider={!isLast}
                    sx={{ flexDirection: "column", alignItems: "flex-start" }}
                  >
                    <Box>
                      <ListItemText>
                        <Typography>{title}</Typography>
                      </ListItemText>
                      <ListItemSecondaryAction>
                        <IconButton onClick={onClick}>
                          {isOpen ? <ChevronUp /> : <ChevronDown />}
                        </IconButton>
                      </ListItemSecondaryAction>
                    </Box>
                    <Box>
                      <Collapse in={isOpen}>
                        <Box p={2}>
                          {React.Children.toArray(
                            text.map((line) => (
                              <Typography
                                variant="body1"
                                component="p"
                                style={{ marginBottom: 8 }}
                              >
                                {line}
                              </Typography>
                            ))
                          )}
                          {link && (
                            <Box mt={2}>
                              <Button
                                size="small"
                                variant="text"
                                href={link.href}
                                target="_blank"
                                rel="noreferer noopener"
                              >
                                <Youtube size={18} />
                                <span style={{ marginLeft: 8 }}>
                                  {link.text}
                                </span>
                              </Button>
                            </Box>
                          )}
                        </Box>
                      </Collapse>
                    </Box>
                  </ListItem>
                );
              })}
            </List>
          </Grid>
        </Grid>
      </Bubble>
    </Box>
  );
};
