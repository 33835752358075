import {
  Clubhouse,
  Facebook,
  Instagram,
  Linkedin,
  Pinterest,
  Snapchat,
  Tiktok,
  Twitch,
  Twitter,
  Youtube,
  Podcast,
} from "../icons";

export enum CanalName {
  FACEBOOK = "Facebook",
  INSTAGRAM = "Instagram",
  TWITTER = "Twitter",
  TIKTOK = "Tiktok",
  SNAPCHAP = "Snapchat",
  TWITCH = "Twitch",
  PINTEREST = "Pinterest",
  LINKEDIN = "LinkedIn",
  YOUTUBE = "Youtube",
  CLUBHOUSE = "Clubhouse",
  PODCAST = "Podcast",
}

export interface Canal {
  name: CanalName;
  logo: JSX.Element;
  enabled?: boolean;
}

const FACEBOOK: Canal = {
  name: CanalName.FACEBOOK,
  logo: <Facebook size={36} />,
  enabled: true,
};

const INSTAGRAM: Canal = {
  name: CanalName.INSTAGRAM,
  logo: <Instagram size={36} />,
  enabled: true,
};

const TWITTER: Canal = {
  name: CanalName.TWITTER,
  logo: <Twitter size={36} />,
  enabled: false,
};

const TIKTOK: Canal = {
  name: CanalName.TIKTOK,
  logo: <Tiktok size={36} />,
  enabled: true,
};

const SNAPCHAP: Canal = {
  name: CanalName.SNAPCHAP,
  logo: <Snapchat size={36} />,
  enabled: true,
};

const TWITCH: Canal = {
  name: CanalName.TWITCH,
  logo: <Twitch size={36} />,
  enabled: false,
};

const PINTEREST: Canal = {
  name: CanalName.PINTEREST,
  logo: <Pinterest size={36} />,
  enabled: true,
};

const LINKEDIN: Canal = {
  name: CanalName.LINKEDIN,
  logo: <Linkedin size={36} />,
  enabled: true,
};

const YOUTUBE: Canal = {
  name: CanalName.YOUTUBE,
  logo: <Youtube size={36} />,
  enabled: true,
};

const CLUBHOUSE: Canal = {
  name: CanalName.CLUBHOUSE,
  logo: <Clubhouse size={36} />,
  enabled: true,
};

const PODCAST: Canal = {
  name: CanalName.PODCAST,
  logo: <Podcast size={36} />,
  enabled: true,
};

export const ALL_CANALS: Canal[] = [
  FACEBOOK,
  LINKEDIN,
  INSTAGRAM,
  TWITTER,
  TIKTOK,
  SNAPCHAP,
  TWITCH,
  PINTEREST,
  YOUTUBE,
  CLUBHOUSE,
  PODCAST,
];

export const CANALS: Canal[] = ALL_CANALS.sort((a, b) => {
  if (a.enabled && !b.enabled) return -1;
  if (!a.enabled && b.enabled) return 1;
  return 0;
});

export const getCanal = (
  name: CanalName,
  forceEnabled?: boolean
): Canal | undefined =>
  (forceEnabled ? CANALS : ALL_CANALS).find((canal) => canal.name === name);
