import { SvgIconProps } from './SvgIcon';

export const Clubhouse = ({ size = 48 }: SvgIconProps) => {
  return (
    <svg
      id='clubhouse'
      fill='none'
      height={size}
      viewBox='0 0 24 24'
      width={size}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13.7474 21C12.8742 20.9836 10.6912 20.4882 8.94489 18.6379C6.76194 16.3249 4.79845 14.7594 4.42781 14.3655C4.21797 14.1425 3.20359 13.257 3.83647 12.1901C4.16461 11.6369 5.16631 11.7329 5.71612 12.1901C5.98242 12.4115 8.51147 14.6298 8.6812 14.9742C8.27527 14.3834 3.92847 10.1131 3.51038 9.70135C2.91044 9.1105 2.80194 8.23723 3.3865 7.62443C3.89888 7.08729 4.6931 7.06043 5.25725 7.54385L9.77746 12.2333C9.85606 12.3148 9.99086 12.2985 10.0477 12.2005V12.2005C10.0852 12.1358 10.0761 12.0541 10.0253 11.9992L4.74537 6.29054C4.24304 5.79817 3.88191 4.67018 4.6931 4.17781C5.5888 3.63414 6.45871 4.40161 6.74924 4.76866L11.1626 10.1768C11.2374 10.2685 11.3685 10.2905 11.4691 10.2284V10.2284C11.5908 10.1533 11.6188 9.98885 11.528 9.87836C10.4424 8.55641 8.427 6.04538 7.85168 5.10884C7.24454 4.12051 7.7752 3.40792 8.11642 3.17516C8.52872 2.89392 9.52052 2.84049 10.1108 3.87343C10.6756 4.86176 12.9347 8.00938 13.9936 9.45964C14.2972 9.87541 14.9114 10.5411 15.5468 10.3549C16.341 10.1221 16.4822 8.95831 16.6058 8.22423C16.7293 7.49014 17.4176 6.02197 18.9355 6.00407C20.4533 5.98616 19.9768 7.50805 19.7297 7.86614C19.4826 8.22423 18.5648 9.85354 18.8649 11.5724C19.1649 13.2912 19.7826 15.1533 18.8649 17.4092C18.1307 19.214 16.9799 20.2925 16.1562 20.5312'
        stroke='black'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
