import { SvgIconProps } from './SvgIcon';

export const Twitch = ({ size = 48 }: SvgIconProps) => {
  return (
    <svg
      enableBackground='new 0 0 50 50'
      id='twitch'
      version='1.1'
      viewBox='0 0 50 50'
      xmlSpace='preserve'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width={size}
      height={size}
    >
      <path
        d='M45,1H5C2.8,1,1,2.8,1,5v40c0,2.2,1.8,4,4,4h40c2.2,0,4-1.8,4-4V5C49,2.8,47.2,1,45,1z'
        fill='#6441A5'
      />
      <g id='_x7C___x7C_'>
        <path
          d='M31,36h-6l-3,4h-4v-4h-7V15.1l2-5.1h26v18L31,36z M36,27V13H15v19h6v4l4-4h6L36,27z'
          fill='#FFFFFF'
          id='Dialog'
        />
        <rect fill='#FFFFFF' height='8' id='_x7C_' width='3' x='28' y='18' />
        <rect fill='#FFFFFF' height='8' id='_x7C__2_' width='3' x='22' y='18' />
      </g>
    </svg>
  );
};
