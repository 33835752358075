import { AxiosResponse } from 'axios';
import { post } from './instance';

export interface StatsPayload {
  views?: number;
  likes?: number;
  comments?: number;
  pageLikes?: number;
  clicks?: number;
  listeners?: number;
}

export interface StatsResponse {
  views?: number;
  likes?: number;
  comments?: number;
  pageLikes?: number;
  clicks?: number;
  listeners?: number;

  engagementRate?: number;
  postValue?: number;
}

export interface CalculatorResponse {
  id: string;

  facebook?: StatsResponse;
  linkedin?: StatsResponse;
  twitter?: StatsResponse;
  youtube?: StatsResponse;
  instagram?: StatsResponse;
  pinterest?: StatsResponse;
  clubhouse?: StatsResponse;
  tiktok?: StatsResponse;
  twitch?: StatsResponse;
  snapchat?: StatsResponse;
  podcast?: StatsResponse;
}

export const calculateCompanyScore = (
  email: string,
  options?: {
    id?: string;
    facebook?: StatsPayload;
    twitter?: StatsPayload;
    instagram?: StatsPayload;
    linkedin?: StatsPayload;
    youtube?: StatsPayload;
    clubhouse?: StatsPayload;
    tiktok?: StatsPayload;
    pinterest?: StatsPayload;
    twitch?: StatsPayload;
    snapchat?: StatsPayload;
    podcast?: StatsPayload;
  }
): Promise<AxiosResponse<CalculatorResponse>> => {
  return post(`/calculator/company/score`, {
    email,
    ...(options ?? {}),
  });
};
