import { useState, useEffect } from "react";

import { Box, LinearProgress, Typography, useTheme } from "@mui/material";

export interface EngagementRateProps {
  value: number;
  animated?: boolean;
}

export const EngagementRate = ({ value, animated }: EngagementRateProps) => {
  const theme = useTheme();
  const [progess, setProgress] = useState(animated ? 0 : value);

  useEffect(() => {
    if (!animated) return;
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress >= value) {
          return value;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, value);
      });
    }, 150);

    if (progess === value) {
      clearInterval(timer);
    }

    return () => {
      clearInterval(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <Box display="flex" alignItems="center" width="100%">
      <Box flexGrow={1}>
        <LinearProgress
          variant="determinate"
          value={Math.min(progess * 37, 100)}
          sx={{
            height: 10,
            borderRadius: 5,
            "& .MuiLinearProgress-colorPrimary": {
              backgroundColor: theme.palette.grey[200],
            },
            "& .MuiLinearProgress-bar": {
              borderRadius: 5,
              backgroundColor: theme.palette.primary.light,
            },
          }}
        />
      </Box>
      <Box minWidth={35} color="common.black" ml={1}>
        <Typography variant="body2">
          {progess === 0
            ? "0%"
            : progess >= 0.2
            ? `${progess.toFixed(2)}%`
            : "< 0.2%"}
        </Typography>
      </Box>
    </Box>
  );
};
